var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "ui segment sideblock" }, [
    _vm.isLoading
      ? _c(
          "div",
          { staticClass: "top-row loading" },
          [_c("t-placeholder", { attrs: { lines: 1 } })],
          1
        )
      : _c("div", { staticClass: "top-row" }, [
          _c(
            "div",
            { class: "icon " + _vm.color },
            [_c("t-icon", { attrs: { icon: [_vm.familyIcon, _vm.icon] } })],
            1
          ),
          _c("div", [
            _c("h2", { domProps: { textContent: _vm._s(_vm.paymentMethod) } })
          ])
        ]),
    _c("div", { staticClass: "bottom-row" }, [
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "left-side" },
            [
              _c("t-placeholder", {
                attrs: { lines: 3, header: true, paragraph: false }
              })
            ],
            1
          )
        : _c("div", { staticClass: "left-side" }, [
            _c("span", { staticClass: "item" }, [_vm._v("Saldo a liberar")]),
            _c("span", {
              staticClass: "value",
              domProps: { textContent: _vm._s(_vm.toBeReleased) }
            }),
            _c("span", { staticClass: "currency" }, [_vm._v("BRL")])
          ]),
      _vm.isLoading
        ? _c(
            "div",
            { staticClass: "right-side" },
            [
              _c("t-placeholder", {
                attrs: { lines: 3, header: true, paragraph: false }
              })
            ],
            1
          )
        : _c("div", { staticClass: "right-side" }, [
            _c("span", { staticClass: "item" }, [_vm._v("Saldo disponivel")]),
            _c("span", {
              staticClass: "value",
              domProps: { textContent: _vm._s(_vm.avaiable) }
            }),
            _c("span", { staticClass: "currency" }, [_vm._v("BRL")]),
            _vm.dueDate
              ? _c("div", { staticClass: "more-info" }, [
                  _c("span", [_vm._v("agendado para")]),
                  _c("span", [
                    _vm._v("todo dia " + _vm._s(_vm.dueDate) + "\n          "),
                    _c(
                      "span",
                      {
                        staticClass: "tui popup",
                        attrs: {
                          "data-html":
                            "Informar que existe um prazo para realização do saque."
                        }
                      },
                      [
                        _c("t-icon", {
                          staticClass: "icon",
                          attrs: { icon: ["far", "info-circle"] }
                        })
                      ],
                      1
                    )
                  ])
                ])
              : _vm._e()
          ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }