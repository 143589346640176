<template>
<div class="ui segment sideblock">
  <div v-if="isLoading" class="top-row loading">
    <t-placeholder :lines="1" />
  </div>
  <div v-else class="top-row">
    <div :class="`icon ${color}`">
      <t-icon :icon="[ familyIcon, icon ]" />
    </div>
    <div>
      <h2 v-text="paymentMethod" />
    </div>
  </div>
  <div class="bottom-row">
    <div v-if="isLoading" class="left-side">
      <t-placeholder :lines="3" :header="true" :paragraph="false" />
    </div>
    <div v-else class="left-side">
      <span class="item">Saldo a liberar</span>
      <span class="value" v-text="toBeReleased" />
      <span class="currency">BRL</span>
    </div>
    <div v-if="isLoading" class="right-side">
      <t-placeholder :lines="3" :header="true" :paragraph="false" />
    </div>
    <div v-else class="right-side">
      <span class="item">Saldo disponivel</span>
      <span class="value" v-text="avaiable" />
      <span class="currency">BRL</span>
      <div v-if="dueDate" class="more-info">
        <span>agendado para</span>
        <span>todo dia {{ dueDate }}
          <span class="tui popup" :data-html="`Informar que existe um prazo para realização do saque.`">
            <t-icon :icon="[ 'far', 'info-circle' ]" class="icon"/>
          </span>
        </span>
      </div>
    </div>
  </div>
</div>
</template>
<script>
/**
 * familyIcon
 * icon
 * paymentMethod
 * Value of: balance to be released
 * Value of: avaiable balance
 * Due Date by: Date
 */
export default {

  name: 'TSideBlock',

  props: {

    isLoading: {
      type: Boolean,
      default: false
    },

    toBeReleased: {
      type: String,
      default: ''
    },
    avaiable: {
      type: String,
      default: ''
    },
    dueDate: {
      type: String,
      default: ''
    },
    familyIcon: {
      type: String,
      default: ''
    },
    icon: {
      type: String,
      default: ''
    },
    color: {
      type: String,
      default: 'default'
    },
    paymentMethod: {
      type: String,
      default: ''
    }
  },

  mounted() {
    $('.tui.popup').popup({
      transition: 'fade'
    })
  }

}
</script>
<style lang="scss" scoped>
@import '@/assets/sass/colors';

.ui.segment {
  padding: 0;
  margin-bottom: 20px;

  &.sideblock {
    .top-row {
      padding: 20px;
      &:not(.loading) {
        display: flex;
        flex-direction: row;
      }

      .icon {
        font-size: 20px;
        line-height: 23px;
        margin-right: 20px;
        color: $white;
        padding: 8px 10px;
        border-radius: 3px;

        &.default {
          background: $blue-300;
        }

        &.purple {
          background: $purple-300;
        }
      }
    }

    .bottom-row {
      display: flex;
      justify-content: space-between;
      border-top: $border;
      font-size: 12px;

      .left-side {
        width: 50%;
        padding: 20px;

        span {
          display: flex;
          flex-direction: row;
        }

        .value {
          color: $blue-300;
        }
      }

      .right-side {
        width: 50%;
        padding: 20px;
        border-left: $border;

        span {
          display: flex;
          flex-direction: row;
        }

        .more-info {
          margin-top: 10px;

          .icon {
            margin-top: 3px;
            margin-left: 4px;
          }
        }

        .value {
          color: $green-300;
        }

        .more-info {
          color: $gray-600;
        }
      }

      .value {
        font-size: 24px;
        line-height: 31px;
        font-weight: 700;
      }
    }
  }
}
</style>
